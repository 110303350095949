import { gql } from 'apollo-boost';
import defaults from './defaults';

export const saveCoupon = (_, variables, { getCacheKey, cache }) => {
  const id = getCacheKey({ __typename: 'Coupon', id: 1 });
  const fragment = gql`
    fragment coupon on Coupon {
      amount_off
      coupon
      coupon_applied
      duration
      duration_in_months
      metadata {
        additional_terms
        button_label
        coupon_trial_period
        price_detail
        promotion_accepted
        subtitle
      }
      percent_off
    }
  `;
  const coupon = cache.readFragment({ fragment, id });
  const data = { ...coupon, ...variables };
  cache.writeData({ id, data });
  return null;
};

export const clearCoupon = (_, __, { cache }) => {
  cache.writeData({ data: defaults });
  return null;
};
