// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-freetrial-14-js": () => import("./../../../src/pages/freetrial14.js" /* webpackChunkName: "component---src-pages-freetrial-14-js" */),
  "component---src-pages-freetrial-30-js": () => import("./../../../src/pages/freetrial30.js" /* webpackChunkName: "component---src-pages-freetrial-30-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-log-in-js": () => import("./../../../src/pages/log-in.js" /* webpackChunkName: "component---src-pages-log-in-js" */),
  "component---src-pages-pick-plan-js": () => import("./../../../src/pages/pick-plan.js" /* webpackChunkName: "component---src-pages-pick-plan-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-secure-index-js": () => import("./../../../src/pages/secure/index.js" /* webpackChunkName: "component---src-pages-secure-index-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-upgrade-js": () => import("./../../../src/pages/upgrade.js" /* webpackChunkName: "component---src-pages-upgrade-js" */)
}

