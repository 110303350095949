/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { ApolloLink } from 'apollo-boost';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-fetch';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import * as CouponResolvers from './src/graphql/coupon/resolvers';
import defaults from './src/graphql/coupon/defaults';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  const uploadLink = createUploadLink({
    uri: process.env.BACKEND_URL,
    credentials: 'include'
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('tiu_token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'TIU-Authorization': token ? `${token}` : ''
      }
    };
  });

  const cache = new InMemoryCache();

  persistCache({ cache, storage: window.sessionStorage });

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, uploadLink]),
    fetch,
    cache,
    resolvers: {
      Mutation: CouponResolvers
    }
  });
  cache.writeData({
    data: defaults
  });

  client.onResetStore(() => {
    cache.writeData({ data: defaults });
  });

  const stripePromise = loadStripe(process.env.STRIPE_CLIENT_KEY);
  return (
    <ApolloProvider client={client}>
      <Elements stripe={stripePromise}>{element}</Elements>
    </ApolloProvider>
  );
};

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: 'setEnv',
      env: process.env.ACTIVE_ENV
    });
  }
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq('track', 'ViewContent');
    fbq('dataProcessingOptions', ['LDU'], 0, 0);
  }
};
