const defaults = {
  Coupon: {
    __typename: 'Coupon',
    amount_off: null,
    coupon_applied: null,
    coupon: null,
    duration_in_months: null,
    duration: null,
    id: 1,
    metadata: {
      __typename: 'StripeCouponMetadata',
      additional_terms: null,
      button_label: null,
      coupon_trial_period: null,
      price_detail: null,
      promotion_accepted: null,
      subtitle: null
    },
    percent_off: null
  }
};

export default defaults;
